import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from 'react-helmet'

const Impressum = () => (
    <Layout>
        <Helmet
            bodyAttributes={{
                class: 'is--default-page'
            }}
        />
        <SEO title="Impressum"/>
        <h1 className="text-3xl md:text-4xl uppercase font-bold mb-4 mt-24 text-black">Impressum</h1>
        <div className="text-lg hentry">

            <p><strong>de Pretis GmbH</strong><br />
                Moritschstraße 3<br />
                9500 Villach</p>

            <p>Unternehmensberatung & Handelsagentur</p>

            <p>Tel 00436644418335<br />
            E-Mail office@depretis.gmbh</p>

            <p>FN: 112824 v FB-Gericht: Klagenfurt<br />
            Sitz: 9500 Villach<br />
            UID: ATU26548705</p>

            <p><strong>Geschäftsführer:</strong> Dipl.-Ing. Daniel de Pretis</p>

            <p><strong>Behörde gem. ECG:</strong><br />
            Magistrat der Stadt Villach<br />
            GISA: 33118192 & 33118147<br />
            Gewerbeordnung: <a className="underline text-dark hover:text-opacity-70" href="www.ris.bka.gv.at" target="_blank">www.ris.bka.gv.at</a><br />
            Sofern nicht anders vereinbart gelten die aktuellen AGB Empfehlungen des jeweiligen WKO Fachverbands.</p>

            <p><strong>Cookies:</strong><br />
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>

            <p><strong>Datenschutzerklärung:</strong><br />
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten, z.B. Name, Anschrift oder EMail-Adresse beim Ausfüllen des Kontaktformulares vom Ersteller eingetragen werden, geschieht dies zwangsläufig stets auf freiwilliger Basis des Erstellers. Diese Daten werden dennoch ohne der ausdrücklichen Zustimmung des Erstellers nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei Kommunikation via E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff Dritter ist nicht möglich.</p>

            <p>Keine Abmahnung ohne vorherigen Kontakt:<br />
            Sollte der Inhalt oder die Aufmachung dieser Seiten fremde Rechte Dritter oder gesetzliche Bestimmungen verletzen, so bitten wir um eine entsprechende Nachricht ohne Kostennote. Wir garantieren, dass die zu Recht beanstandeten Passagen unverzüglich entfernt werden, ohne dass von Ihrer Seite die Einschaltung eines Rechtsbeistandes erforderlich ist. Dennoch von Ihnen ohne vorherige Kontaktaufnahme ausgelöste Kosten werden vollumfänglich zurückgewiesen und ggf. Gegenklage wegen Verletzung vorgenannter Bestimmungen eingereicht.</p>

            <p>Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: <a className="underline text-dark hover:text-opacity-70" href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</p>

        </div>
    </Layout>
)

export default Impressum
